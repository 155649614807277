import React, { useEffect } from "react";
import view from "./blog.module.scss";
import { Link } from "gatsby";
import { FacebookShareButton, TwitterShareButton, FacebookIcon } from "react-share";

const Blog = (props) => {
  const { data, title } = props;
  const location = typeof window !== "undefined" && window.location;
  const shareLink = location.href;
  const blogCount = data.blogposts.length;

  useEffect(() => {
    let blogPTags = document.getElementsByTagName("p");
    for (let i = 0; i < blogPTags.length; i++) {
      if (blogPTags[i].innerHTML === "" || blogPTags[i].innerHTML === null) {
        blogPTags[i].setAttribute("class", "rich-text-inline");
      }
    }
    const paraClasses = document.querySelectorAll(".rich-text-inline");
    for (let i = 0; i < paraClasses.length; i++) {
      paraClasses[i].style.height = "25px";
    }
  }, []);

  function handleThumbnailAltText() {
    if (!data.thumbnailAltText || data.thumbnailAltText === "") {
      return `${title} Blog Image`;
    } else {
      return data.thumbnailAltText;
    }
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return inputDate;
    }
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  const isFutureDate = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return date > today;
  };

  const formattedDate = formatDate(data.publicationDate);
  const isFuture = isFutureDate(data.publicationDate);

  const CustomXIcon = () => (
    <svg viewBox="0 0 64 64" width="32" height="32">
      <circle cx="32" cy="32" r="32" fill="#000000"></circle>
      <path
        d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
        fill="white"
      ></path>
    </svg>
  );

  return (
    <>
      <div className={`${view["blog-main-content"]}`}>
        {title ? <h1 style={{ marginBottom: 0 }}>{title}</h1> : null}
        {data.publicationDate ? (
          <p style={{ fontStyle: "italic", marginTop: 5 }}>
            {isFuture ? "Scheduled: " : "Published: "} {formattedDate}
          </p>
        ) : null}

        <div className={`${view["blog-underline"]}`}></div>

        {/* SHARE GOES HERE */}
        <FacebookShareButton style={{ marginRight: 8 }} url={`${shareLink}`}>
          <FacebookIcon size={32} round bgStyle={{ fill: "black" }} />
        </FacebookShareButton>
        <TwitterShareButton url={`${shareLink}`}>
          <CustomXIcon />
        </TwitterShareButton>
        {data.thumbnailImage ? (
          <div style={{ width: "100%", maxWidth: 750, margin: "0 auto" }}>
            <img
              className={`${view["blog-main-thumbnail"]}`}
              src={data.thumbnailImage}
              alt={handleThumbnailAltText()}
            />
          </div>
        ) : null}
        <div
          className={`${view["rich-para-cont"]}`}
          style={{ fontSize: 15, lineHeight: 1.8 }}
          dangerouslySetInnerHTML={{ __html: data.blogContent }}
        ></div>
        <div className={`${view["tags"]}`}>
          <p>
            {blogCount > 0 ? <>Posted in:&nbsp;</> : <></>}
            {data.blogposts.map((tags, index) => {
              const tagNameLower = tags?.tag?.toLowerCase();
              if (tags?.tag === "undefined") {
                return <></>;
              }

              return (
                <>
                  {blogCount > 1 ? (
                    <>
                      {index === blogCount - 1 ? <span>and&nbsp;</span> : <></>}
                      <Link href={`/categories/${tagNameLower}`}>{tags.tag}</Link>
                      {index === blogCount - 1 ? <></> : <span>,&nbsp;</span>}
                    </>
                  ) : (
                    <>
                      <Link href={`/categories/${tagNameLower}`}>{tags?.tag}</Link>&nbsp;
                    </>
                  )}
                </>
              );
            })}
          </p>
        </div>
      </div>
    </>
  );
};

export default Blog;
